import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import imageLinks from "../../../../../assets/images";
import { SocketContext } from "../../../../../lib/socket/context/socket";
import { IS_NOT_TYPING, IS_TYPING } from "../../../../../lib/socket/events";
import { Button } from "../../../../ui";
import { useIsTyping } from "use-is-typing";
import PoweredBy from "../../../../common/PoweredBy/PoweredBy";
import UploadIcons from "./UploadIcons/UploadIcons";
import { dataQueryStatus } from "../../../../../utils/formatHandlers";
import {
    expandKeyboardTextArea,
    isDeviceMobileTablet,
    isIOSDevice,
    removeMobileKeyboard,
    scrollChatToBottom,
    showIphoneKeyboard,
} from "../../../../../utils/helper";
import { INPUT_NEEDED, formInputTypes } from "../MessageBody/Messages/enums";
import { IMAGE } from "./UploadIcons/enum";
import "./LiveChatInput.scss";
import UploadedFiles from "./UploadIcons/UploadedFiles/UploadedFiles";
import { defaultTemplates } from "hoc/AppTemplateWrapper/enum";
import { datePickerStages } from "./DatePickerWrapper/enum";
import SmallLoader from "components/ui/SmallLoader/SmallLoader";
import { useWindowSize } from "utils/hooks";
import FileUploadWrapper from "components/common/FileUploadWrapper/FileUploadWrapper";
import LiveChatInputTypes from "./LiveChatInputTypes/LiveChatInputTypes";
import LiveChatInputButton from "./LiveChatInputButton/LiveChatInputButton";

const { DATE_VALUE, PICK_DATE } = datePickerStages;
const { LOADING, ERROR, DATAMODE } = dataQueryStatus;
const { NUMERIC, MULTISELECT } = formInputTypes;

const { RELAXED } = defaultTemplates;

const LiveChatInput = ({
    handleNewMessage,
    ticketId,
    fetchingInputStatus,
    allowUserInput,
    inputType,
    currentFormElement,
    disableInput,
    uploads,
    updateUploads,
    isDateFormElement,
    mssgSendStatus,
    messages,
    trackUserIsTyping,
    setInputFocus,
    showAttachmentButton,
    defaultInstructionalLabel
}) => {
    const [isTyping, inputRef] = useIsTyping();
    const inputContainerRef = useRef();

    const [selectedMedia, setSelectedMedia] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState("");
    const [status, setStatus] = useState();
    const [showModal, toggleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [datePickerStage, setDatePickerStage] = useState(DATE_VALUE);

    const isDisabled = fetchingInputStatus || !allowUserInput;
    const [request, updateRequest] = useState({
        message: "",
        fileAttachments: []
    });


    const handleFileUpload = (fileUrl, fileType, fileName) => {
        // Ensure file details are defined before updating the state
        const newFile = {
            fileAttachmentUrl: fileUrl,
            fileAttachmentType: fileType,
            fileAttachmentName: fileName,
        };
    
        updateRequest((prev) => {
            // Check if fileAttachmentName already exists in the fileAttachments array
            const existingFileIndex = prev.fileAttachments.findIndex(
                (file) => file.fileAttachmentName === fileName
            );
    
            let updatedFileAttachments;
    
            if (existingFileIndex !== -1) {
                // If the fileAttachmentName exists, create a new array with the updated file
                updatedFileAttachments = prev.fileAttachments.map((file, index) =>
                    index === existingFileIndex ? newFile : file
                );
            } else {
                // If fileAttachmentName doesn't exist, append the new file to the array
                updatedFileAttachments = [...prev.fileAttachments, newFile];
            }
    
            // Return the new state with updated fileAttachments
            return {
                ...prev,
                fileAttachments: updatedFileAttachments,
            };
        });
    };
    
    const socket = useContext(SocketContext);

    const clearUserInput = () => {
        updateRequest({
            message: "",
            fileAttachments: [],
        });
        updateUploads([]);
        setDatePickerStage(DATE_VALUE);
        setLoading(false);
    };

    const handleRemoveFile = async (fileName, fileIndex) => {
        const uploadData = await uploads.filter((upload) => upload.fileAttachmentName !== fileName);
        const requestFileAttachments = await request.fileAttachments.filter(
            (file) => file.fileAttachmentName !== fileName
        );
        // needs clean up
        await updateRequest((prev) => {
            return {
                ...prev,
                fileAttachments: requestFileAttachments
            };
        });
        await updateUploads(uploadData);

        setErrors((prev) => ({ ...prev, file: "" }));
        setLoading(false);
    };

    const sendNewMessage = () => {
        if (
            request?.fileAttachments[0]?.fileAttachmentUrl ||
            isDateFormElement ||
            isFormElementMultiselect ||
            request?.message?.length > 0
        ) {
            setLoading(true);
        } else {
            setLoading(false);
            return;
        }

        handleNewMessage(request, clearUserInput);
        setErrors((prev) => ({ ...prev, file: "" }));
    };

    const handleAndroidKeyboard = () => {
        window.addEventListener("resize", (e) => {
            const messageBody = document.getElementById("messageBody");
            messageBody.style.scrollBehavior = "smooth";
            messageBody.scrollTop = messageBody.scrollHeight;
        });
    };

    const resetInput = () => {
        const textAreaField = document.getElementById("form__input");
        const textAreaFieldContainer =
            document.getElementById("chat__input--group");
        textAreaFieldContainer.style.height = "58px";
        textAreaFieldContainer.style.borderRadius = "100px";
        textAreaField.style.height = "58px";
    };

    const textFieldHeightRef = useRef(0);

    const handleInputFocus = () => {
        setInputFocus?.(true);
        scrollChatToBottom();
        if (isIOSDevice()) {
            showIphoneKeyboard();
        } else {
            if (isDeviceMobileTablet()) {
                handleAndroidKeyboard();
            }
        }
    };

    const handleInputBlur = () => {
        setInputFocus?.(request?.message?.length > 0 ? true : false);
        return;
    };

    const handleTyping = (e) => {
        trackUserIsTyping();
        scrollChatToBottom();
        //    if (document.activeElement.id === "send__button") {
        //     document.activeElement.click();
        // }
        let { value } = e.target;
        if (inputType === NUMERIC) {
            value = value.replace(/\D/g, "");
        }
        updateRequest({ ...request, message: value });
        setInputFocus(true);
    };

    useEffect(() => {
        const decidedEvent = isTyping ? IS_TYPING : IS_NOT_TYPING;
        socket.emit(decidedEvent, { ticketId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTyping]);

    useEffect(() => {
        return () => {
            socket.emit(IS_NOT_TYPING, { ticketId });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDatePickerStage = () => {
        setDatePickerStage(DATE_VALUE);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        sendNewMessage();
        setInputFocus?.(false);
        resetInput();
    };

    const handleUploadRetry = () => {
        setErrors((prev) => ({ ...prev, file: "" }));
        setErrorMssg()
        updateRequest((prev) => {
            return {
                ...prev,
                fileAttachments: []
            };
        });
        updateUploads([]);
        setLoading(false);
    }

    const handleOnDoneEvent = (e) => {
        const activeElementId = document.activeElement?.id;
        if (
            isIOSDevice() &&
            !(
                activeElementId === "send_button" ||
                activeElementId !== "form__input"
            ) &&
            e?.key === undefined
        ) {
            removeMobileKeyboard();
        }
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13) {
            handleSubmit(e);
        } else if (e.keyCode === 13 && e.metaKey) {
            e.preventDefault()
        }
    }
    const btnDisabled =
        uploads?.length > 0
            ? status === LOADING || status === ""
            : isDisabled || request?.message === "";

    const { defaultTemplate } = useSelector(
        (state) => state?.chat?.chatSettings
    );    

    const isRelaxedTemplate = defaultTemplate === RELAXED;

    const { formElementType } = currentFormElement || {};

    const isFormElementImage = formElementType === IMAGE;

    const isFormElementMultiselect = formElementType === MULTISELECT;

    const isFinalDatePickerStage = datePickerStage === PICK_DATE;

    const { width } = useWindowSize();

    const isTablet = width <= 768;

    const lastMessage = messages[messages.length - 1];

    const userInstructionLabel = defaultInstructionalLabel ? defaultInstructionalLabel :
        uploads?.length > 0
            ? null
            : lastMessage?.branchOptions?.length > 0
                ? "Choose an option above"
                : lastMessage?.messageType === "ACTION_INFO"
                    ? lastMessage?.messageActionType === INPUT_NEEDED
                        ? "Please we need a response from you"
                        : "Please wait for a response from us"
                    : null;

    const renderUploadedFiles = () => {
        return (
            <>
                <UploadedFiles
                    uploads={uploads}
                    status={status}
                    handleRemoveFile={
                        handleRemoveFile
                    }
                    icon={
                        imageLinks?.svg
                            ?.attachment2
                    }
                    handleRetry={(file) => {
                        updateUploads([file]);
                    }}
                    maximize={(
                        fileAttachmentType,
                        fileAttachmentName,
                        fileAttachmentUrl
                    ) => {
                        setSelectedMedia({
                            fileAttachmentType,
                            fileAttachmentName,
                            fileAttachmentUrl,
                        });
                        toggleModal(true);
                    }}
                    disableClick={
                        status !== DATAMODE
                    }
                />
                {
                    uploads?.map((x) => {
                        return (
                            <FileUploadWrapper
                                setStatus={setStatus}
                                setError={setErrorMssg}
                                file={x}
                                setUploadedFileKey={(
                                    fileKey,
                                    file
                                ) =>
                                    handleFileUpload(
                                        fileKey,
                                        file?.fileAttachmentType,
                                        file?.fileAttachmentName
                                    )
                                }>
                                <div></div>
                            </FileUploadWrapper>
                        )

                    })
                }

            </>

        )
    }

    useEffect(() => {
        // if it is button and type submit, then call removeMobileKeyboard
        // else if and if it is not input, then call removeMobileKeyboard
        document?.body?.addEventListener?.("click", () => {
            const activeElementId = document.activeElement?.id;
            if (
                activeElementId === "send_button" ||
                activeElementId !== "form__input"
            ) {
                removeMobileKeyboard();
            }
        });

        return () => document?.body?.removeEventListener?.("click", () => { });

        // eslint-disable-next-line
    }, [document, window]);

    useEffect(() => {
        expandKeyboardTextArea(textFieldHeightRef);
        // eslint-disable-next-line
    }, [request?.message]);

    useEffect(() => {
        // alert(`${window.innerHeight}px`)
        const textAreaField = document.getElementById("form__input");

        textAreaField?.addEventListener("blur", handleOnDoneEvent);
        textAreaField?.addEventListener("focusout", (e) => {
            if (
                isIOSDevice()
            ) {
                removeMobileKeyboard();
            }
        });

    }, []);

    return (
        <div className={`chat__input__wrapper`}>
            <div
                id='inputGroup'
                className={`col-md-10 col-12 ${!allowUserInput && isRelaxedTemplate
                    ? "disallowed__section"
                    : ""
                    }`}
                title={!allowUserInput ? "Not Allowed" : "Type a message"}
                ref={inputContainerRef}>

                <form
                    onSubmit={handleSubmit}
                    id='chatInput'
                    className={`
                ${isFormElementMultiselect ? "chatInput-form-select" : ""}
                ${isTablet && uploads.length > 0 ? "chatInputFormSubmit" : ""
                        }`}>

                    <div
                        className={
                            isFormElementMultiselect
                                ? ""
                                : "chat__input--container"
                        }>
                        {(showAttachmentButton && !currentFormElement) && <div className="wrapper-files-container">
                            {renderUploadedFiles()}
                        </div>}
                        <div
                            className={`chat__input--group ${!allowUserInput && !isRelaxedTemplate
                                ? "chat__input--group--workmode__disabled"
                                : ""
                                }`}
                            id='chat__input--group'>
                            {
                                (showAttachmentButton && !isDisabled && !currentFormElement) && <UploadIcons
                                    upload={uploads}
                                    updateUpload={updateUploads}
                                    isDisabled={isDisabled}
                                    setErrors={setErrors}
                                    showModal={showModal}
                                    toggleModal={toggleModal}
                                    selectedMedia={selectedMedia}
                                    icon={
                                        imageLinks?.svg?.attachment
                                    }
                                // currentFormElement={
                                //     currentFormElement
                                // }
                                // label={"Upload X"}
                                />}
                            {(uploads?.length === 0 || showAttachmentButton === true) &&
                                (isFormElementImage ||
                                    isFinalDatePickerStage ||
                                    isFormElementMultiselect) ? (
                                <>
                                    {isDateFormElement ||
                                        isFormElementMultiselect ? (
                                        <LiveChatInputTypes
                                            {...{
                                                currentFormElement,
                                                inputType,
                                                datePickerStage,
                                                request,
                                                updateRequest,
                                                disableInput,
                                                setDatePickerStage,
                                                loading,
                                                isRelaxedTemplate,
                                                handleTyping,
                                                handleInputBlur,
                                                handleInputFocus,
                                                inputRef,
                                                isDisabled,
                                                onEnterPress
                                            }}
                                        />
                                    ) : (
                                        uploads?.length === 0 && (
                                            <UploadIcons
                                                upload={uploads}
                                                updateUpload={updateUploads}
                                                isDisabled={isDisabled}
                                                setErrors={setErrors}
                                                showModal={showModal}
                                                toggleModal={toggleModal}
                                                selectedMedia={selectedMedia}
                                                icon={
                                                    imageLinks?.svg?.attachment2
                                                }
                                                currentFormElement={
                                                    currentFormElement
                                                }
                                                label={"Upload File"}
                                            />
                                        )
                                    )}
                                </>
                            ) : (
                                <>
                                    <div className='chat__input--group--inputs'>
                                        {(uploads?.length === 0 || showAttachmentButton === true) ? (
                                            <>
                                                {userInstructionLabel &&
                                                    !isRelaxedTemplate ? (
                                                    <div className='chat__input--group__choose-option'>
                                                        {mssgSendStatus ===
                                                            LOADING ? (
                                                            <SmallLoader otherClassName='instruction__label__loader' />
                                                        ) : (
                                                            userInstructionLabel
                                                        )}
                                                    </div>
                                                ) : (
                                                    <LiveChatInputTypes
                                                        {...{
                                                            currentFormElement,
                                                            inputType,
                                                            datePickerStage,
                                                            request,
                                                            updateRequest,
                                                            disableInput,
                                                            setDatePickerStage,
                                                            loading,
                                                            isRelaxedTemplate,
                                                            handleTyping,
                                                            handleInputBlur,
                                                            handleInputFocus,
                                                            inputRef,
                                                            isDisabled,
                                                            onEnterPress
                                                        }}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            !showAttachmentButton && renderUploadedFiles()
                                        )}
                                    </div>
                                    {isDisabled && !isRelaxedTemplate ? (
                                        ""
                                    ) : uploads?.length > 0 && !showAttachmentButton ? (
                                        ""
                                    ) : userInstructionLabel ? (
                                        ""
                                    ) : (
                                        <Button
                                            type='submit'
                                            text={"Send"}
                                            icon={
                                                <ReactSVG
                                                    src={imageLinks?.svg?.send}
                                                />
                                            }
                                            classType='default'
                                            id='send__button'
                                            otherClass={`send__button ${!btnDisabled ? "active" : ""
                                                } ${mssgSendStatus === LOADING
                                                    ? "active"
                                                    : ""
                                                }`}
                                            loading={
                                                isFinalDatePickerStage
                                                    ? disableInput
                                                    : mssgSendStatus === LOADING
                                            }
                                            disabled={
                                                (btnDisabled ||
                                                    fetchingInputStatus ||
                                                    status === LOADING) &&
                                                !(mssgSendStatus === ERROR)
                                            }
                                            loaderClassName='send__button__loader'
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {(errors?.file || errorMssg) && (
                        <p className='file__error' onClick={handleUploadRetry}>
                            {errors?.file || errorMssg}, <b>Tap to retry</b>
                        </p>
                    )}
                    {mssgSendStatus === ERROR && (
                        <div className='chat__input__error' onClick={handleSubmit}>
                           Could not complete last action. <b>Tap to retry</b>
                        </div>
                    )}

                    <LiveChatInputButton
                        hasUploads={uploads?.length > 0}
                        {...{
                            isDateFormElement,
                            isFormElementImage,
                            isTablet,
                            isRelaxedTemplate,
                            isFinalDatePickerStage,
                            isFormElementMultiselect,
                            disableInput,
                            status,
                            mssgSendStatus,
                            btnDisabled,
                            handleDatePickerStage,
                            fetchingInputStatus,
                            loading,
                            showAttachmentButton
                        }}
                    />
                </form>
                <PoweredBy />
            </div>
        </div>
    );
};

export default LiveChatInput;
