import { appMessageUserTypes } from "components/Chat/ChatModule/LiveChat/MessageBody/Messages/enums";
import { ISSUE_DISCOVERY } from "components/Chat/CustomerTicketsContainer/CustomerTickets/common/TicketStatus/enum";
import moment from "moment";
import queryString from "query-string";

export const getErrorMessage = (error) => {
    const response = error?.response;
    const defaultMssg = "Something went wrong. Please try again.";
    const errorMessage =
        response?.status === 503
            ? defaultMssg
            : response?.data
            ? response?.data?.message
            : defaultMssg;

    return errorMessage;
};

export const getSecondsBtwDates = (startDate, endDate) => {
    const seconds = Math.floor(
        (new Date(startDate) - new Date(endDate)) / 1000
    );
    return seconds;
};

export const timeUnit = (interval, unit) => {
    return Math.floor(interval) > 1
        ? `${Math.floor(interval)} ${unit}s`
        : `${Math.floor(interval)} ${unit}`;
};

export function timeSince(reqDate) {
    const date = new Date(reqDate);
    const seconds = Math.floor((new Date() - date) / 1000);

    const intervals = [
        { unit: "yr", seconds: 31536000 },
        { unit: "mo", seconds: 2592000 },
        { unit: "day", seconds: 86400 },
        { unit: "hr", seconds: 3600 },
        { unit: "min", seconds: 60 },
    ];

    for (const { unit, seconds: intervalSeconds } of intervals) {
        const interval = seconds / intervalSeconds;
        if (interval >= 1) {
            return Math.floor(interval) + " " + unit + (Math.floor(interval) > 1 ? 's' : '');
        }
    }

    return seconds < 1 ? "Now" : `${Math.floor(seconds)} s`;
}


export const getFormatedDate = (reqDate, getTimeOnly) => {
    let fullDate = new Date(reqDate);
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const year = fullDate.getFullYear(); // 2019
    const date = fullDate.getDate();

    const monthName = months[fullDate.getMonth()];
    // const dayName = days[fullDate.getDay()];
    let hour = fullDate.getHours();

    let minute = fullDate.getMinutes();

    minute = minute < 10 ? `0${minute}` : minute;

    let timePostfix = hour > 12 ? "pm" : "am";

    hour = hour > 12 ? hour - 12 : hour;

    let formattedTime = `${hour}:${minute} ${timePostfix}`;

    let formattedDate = `${date} ${monthName} ${year}`;

    return getTimeOnly
        ? `${formattedTime}`
        : `${formattedDate}, ${formattedTime}`;
};

export const generateID = (length = 10) => {
    let result = "";
    let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const generateRandomId = (length = 10) => {
    let result = "";
    let characters = "01234567898976543210";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()].sort(
        function (a, b) {
            return new Date(a.deliveryDate) - new Date(b.deliveryDate);
        }
    );
};

export const removeDuplicatesBy = (arr, key) => {
    return arr.filter((obj, index) => {
        return (
            index ===
                arr.findIndex(
                    (o) =>
                        obj[key] === o[key] &&
                        obj.ticketId === o.ticketId &&
                        obj?.messageId === o?.messageId
                ) || !obj.hasOwnProperty(key)
        );
    });
};

export const getFileFormat = (fileName) => {
    const splitted = fileName?.split(".");
    return splitted[splitted?.length - 1];
};

export const truncate = (str, len = 50) => {
    if (str?.length > len) {
        return str?.substring(0, len) + "...";
    } else {
        return str;
    }
};

export const convertSecondsToISOString = (seconds) => {
    let date = new Date(null);
    date?.setSeconds(seconds);
    return date?.toISOString();
};

export const validateEmail = (emailAddress = "") => {
    //eslint-disable-next-line
    let emailTest = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    return emailAddress?.match(emailTest) ? true : false;
};

export const getDaysHrsMinsAndSecs = (noOfSeconds) => {
    let hours = 0;
    let days = 0;
    let mins = 0;
    let secs = 0;

    let totalSeconds = Number(noOfSeconds);

    if (totalSeconds > 86400) {
        let offSeconds = totalSeconds % 86400;
        days = (totalSeconds - offSeconds) / 86400;
        totalSeconds = offSeconds;
    }

    if (totalSeconds < 86400 && totalSeconds >= 3600) {
        let offSeconds = totalSeconds % 3600;
        hours = (totalSeconds - offSeconds) / 3600;
        totalSeconds = offSeconds;
    }

    if (totalSeconds < 3600 && totalSeconds >= 60) {
        let offSeconds = totalSeconds % 60;
        mins = (totalSeconds - offSeconds) / 60;
        totalSeconds = offSeconds;
    }

    secs = totalSeconds;

    return { days, hours, mins, secs, total: noOfSeconds };
};

export const incrementDateTime = (dateTime) => {
    if (dateTime?.length !== 24) {
        return "";
    }
    let updatedMsecs =
        Number.parseInt(dateTime.slice(20, dateTime.length - 1)) + 1;
    return `${dateTime.slice(0, 20)}${updatedMsecs}Z`;
};

export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();

        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });

export const cropImage = async (crop, url, setOutput) => {
    const canvas = document.createElement("canvas");
    const image = await createImage(url);

    const ctx = canvas.getContext("2d");

    if (!ctx) {
        throw new Error("No 2d context");
    }

    // set canvas size to match the bounding box
    canvas.width = image.width;
    canvas.height = image.height;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(image.width / 2, image.height / 2);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // extract the cropped image using these values
    const data = ctx.getImageData(crop.x, crop.y, crop.width, crop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = crop.width;
    canvas.height = crop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");

    setOutput(base64Image);
};

export const getCurrentFormInputRules = (rules, inputType) => {
    const customRules = {
        number00: "maxNum",
        number01: "minNum",
        number02: "hasNumPrefix",
        date00: "minDate",
        date01: "maxDate",
        text01: "maxLength",
        text02: "minLength",
        text00: "isEmail",
        text03: "isLink",
        video00: "maxSize",
        image00: "maxSize",
    };

    let validationRules = {};
    let pattern = "";
    // [A-Za-z]
    // min,max,minLength,maxLength, minSize,maxSize, isALink, isEmail, minDate,maxDate

    // pattern += `\+${customPatterns[inputType]}`;

    for (let i = 0; i < rules?.length; i++) {
        let { baseFormRule, ruleConstraint, ...rest } = rules[i];
        let { formElementRuleCode } = baseFormRule;
        let ruleType = customRules[formElementRuleCode];
        validationRules[ruleType] = {
            ruleConstraint,
            baseFormRule,
            ...rest,
        };
    }

    return { ...validationRules, pattern };
};

export const getNumberPrefix = (rules) => {
    const rule = rules?.find(
        (rule) => rule?.baseFormRule?.formElementRuleCode === "number02"
    );
    return rule?.ruleConstraint;
};

export const getURLPattern = () => {
    //eslint-disable-next-line
    return /^$|(http(s)?:\/\/.)?(\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
};

export const isDeviceMobileTablet = () => {
    let check = false;
    //eslint-disable-next-line
    (function (a) {
        if (
            //eslint-disable-next-line
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            //eslint-disable-next-line
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator?.userAgent || navigator?.vendor || window?.opera);
    return check;
};

export const localeDate = (date) => {
    return moment(date).format("L");
};

export const getDateAndMonth = (reqDate) => {
    let fullDate = new Date(reqDate);
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const date = fullDate.getDate();

    const month = months[fullDate.getMonth()];

    return {
        date,
        month,
    };
};

export const checkArrayEquality = (a, b) => {
    return a?.length === b?.length && a.every((val) => b?.includes(val));
};

export const buildRouteLink = (
    email,
    firstName,
    lastName,
    conversationId,
    isLiveApp,
    workspaceSlug,
    appUserId
) => {
    if (conversationId) {
        return {
            pathname: `/chat?${
                isLiveApp ? "" : `workspaceSlug=${workspaceSlug}`
            }`,
            state: {
                appUserId,
                conversationId,
                firstName,
                lastName,
                email,
            },
        };
    }

    return `/link?${isLiveApp ? "" : `workspaceSlug=${workspaceSlug}&`}${
        conversationId ? `conversationId=${conversationId}&` : ""
    }${appUserId ? `appUserId=${appUserId}&` : ""}${
        firstName ? `firstName=${firstName}&` : ""
    }${lastName ? `lastName=${lastName}&` : ""}${
        email ? `&email=${email}` : ""
    }`;
};

export const isURLWithAppUserId = () => {
    return window.location?.search?.includes("appUserId");
};

export const isIOSDevice = () => {
    return !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const ticketRouteChecker = () => {
    const queryParams = queryString.parse(window.location.search);
    return (
        window.location?.pathname === "/ticket" &&
        queryParams?.appUserId &&
        queryParams?.ticketId
    );
};

export const extractParamsFromUrl = () => {
    const queryParams = queryString.parse(window.location.search);
    const firstName = queryParams?.firstName || "";
    const lastName = queryParams?.lastName || "";
    const email = queryParams?.email || "";
    return { firstName, lastName, email };
};

export const getUserAuthCredentials = (state, user) => {
    const queryParams = queryString.parse(window.location.search);
    const appUserId =
        state?.appUserId ||
        queryParams?.appUserId ||
        user?.userId ||
        generateRandomId();

    const ticketId = state?.ticketId || queryParams?.ticketId;

    const conversationId = state?.conversationId || queryParams?.conversationId;
    return {
        conversationId,
        appUserId,
        ticketId,
    };
};

export const IsElementVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
        ? ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) ||
                  (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const isLastInView = (partiallyVisible = false, messageId) => {
    try {
        let node = document.querySelector(`#${messageId}`);
        // console.log({ node });

        const lastMessage = IsElementVisibleInViewport(node, partiallyVisible);
        // console.log({ lastMessage });
        return lastMessage;
    } catch (err) {
        return false;
    }
};

export const isLastMessageInView = (
    partiallyVisible = false,
    isActualMessage = false
) => {
    try {
        let nodes = document.querySelectorAll("#message__content");
        let lastNode = nodes[nodes?.length - 1];
        if (nodes?.length < 3) {
            return true;
        }
        const lastMessage = IsElementVisibleInViewport(
            isActualMessage ? lastNode : document.getElementById("dummy"),
            partiallyVisible
        );
        return lastMessage;
    } catch (err) {
        return false;
    }
};

export const hasGreetingsContent = (statement) => {
    const greetingsList = [
        "hi",
        "hey",
        "hello",
        "good morning",
        "good afternoon",
        "good day",
        "good night",
        "happy new year",
        "happy new month",
        "yo",
        "you people",
    ];
    return greetingsList?.includes(statement);
};

export const hasUserPassedInvestigateStage = (messages, ticket) => {
    const investigateMesage = messages?.filter((x) =>
        x.messageContent?.includes(
            "Which of these best describes your issue?"
        )
    )[0];

    return !(!investigateMesage && ticket?.ticketPhase === ISSUE_DISCOVERY);
};

export const getUsersFirstMessages = (messages) => {
    let concatenatedMessages = "";

    for (let i = 0; i < messages.length; i++) {
        const { messageType, messageContent, senderType } = messages[i] || {};

        if (messageType === "DEFAULT" && senderType === "THIRD_USER") {
            concatenatedMessages += `${messageContent} `;
        } else {
            if (concatenatedMessages?.length > 0) {
                break;
            }
        }
    }

    return concatenatedMessages;
};

export const scrollChatToBottom = () => {
    // const messageBody = document.getElementById("messageBody");
    // messageBody.style.scrollBehavior = "smooth";
    // messageBody.scrollTop = messageBody.scrollHeight;
    try {
        document
            .getElementById("dummy")
            .scrollIntoView({ behavior: "smooth", block: "end" });
    } catch (err) {}
};

export const shouldDisplayTimeStatus = (
    messages,
    currentMessage,
    currentMessageIndex
) => {
    const { WORKSPACE_AGENT } = appMessageUserTypes;

    const isCurrentMssgReceived =
        currentMessage?.senderType === WORKSPACE_AGENT;

    const nextMessage = messages[currentMessageIndex + 1];
    const isNextMssgReceived = nextMessage?.senderType === WORKSPACE_AGENT;

    if (nextMessage === undefined) {
        return true;
    }

    const isBothMessagesFromAgent = isNextMssgReceived && isCurrentMssgReceived;
    const isBothMessagesFromUser =
        !isNextMssgReceived && !isCurrentMssgReceived;

    if (isBothMessagesFromAgent || isBothMessagesFromUser) {
        const timeStampKey = isBothMessagesFromAgent
            ? "readDate"
            : "deliveryDate";
        const timeDiff = getSecondsBtwDates(
            nextMessage?.[timeStampKey],
            currentMessage?.[timeStampKey]
        );

        if (timeDiff <= 60 && !isNaN(timeDiff)) {
        } else {
            return true;
        }
    } else {
        return true;
    }
};

const getInputContainerSetTopValue = (reducedBy) => {
    const textAreaFieldContainer =
        document.getElementById("chat__input--group");

    const textAreaFieldContainerHeight =
        Number.parseFloat(
            textAreaFieldContainer?.style.height?.replace("px", "")
        ) - 18;

    return `${reducedBy - textAreaFieldContainerHeight}px`;
};

export const showIphoneKeyboard = () => {
    const chatInterface = document.getElementById("messageBody");
    const ticketsHeader = document.getElementById("header__wrapper");
    const textAreaField = document.getElementById("form__input");
    const inputContainerRef = document.getElementById("inputGroup");

    inputContainerRef.style.zIndex = 999;
    inputContainerRef.style.left = "0px";
    inputContainerRef.style.width = "100%";
    // inputContainerRef.style.bottom = "40px";
    inputContainerRef?.addEventListener?.("touchmove", (e) => {
        e.preventDefault();
    });

    // --- this block of code is for controlling the textarea input after being locked by the inputcontainerRef touch move
    let startY = 0;

    textAreaField?.addEventListener?.("touchstart", (e) => {
        startY = e.touches[0].clientY;
    });

    textAreaField?.addEventListener?.("touchmove", (e) => {
        const deltaY = e.touches[0].clientY - startY;
        startY = e.touches[0].clientY;

        // Adjust the scrollTop based on touch movement
        textAreaField.scrollTop += deltaY;

        // Prevent the default touchmove behavior, which may interfere with scrolling
        e.preventDefault();
    });

    const topOffeset = ((window.innerHeight * 350 )/720) - 40;
    const interfaceHeight = (window.innerHeight * 300)/720

    //  end of the block of code. Thanks to ChatGPT

    switch (window.innerHeight) {
        case 798: // iPhone XR, Xs Max iOS 12.4
            inputContainerRef.style.top = getInputContainerSetTopValue(267);
            chatInterface.style.height = "192px";
            break;
        case 752:
        case 751: // iPhone 13
        case 750: // iPhone 13
        case 749: // iPhone 13
            inputContainerRef.style.top = getInputContainerSetTopValue(355);
            chatInterface.style.height = "300px";
            break;
        case 746: // iPhone 13 Pro Max
        case 740: // iPhone 14 Pro Max
            inputContainerRef.style.top = getInputContainerSetTopValue(248);
            chatInterface.style.height = "192px";
            break;
        case 721: // iPhone 12
        case 720: // iPhone 12
            inputContainerRef.style.top = getInputContainerSetTopValue(340);
            chatInterface.style.height = "300px";
            break;
        case 719:
        case 715: // iPhone XR
        case 714: // iPhone X, Xs
            inputContainerRef.style.top = getInputContainerSetTopValue(314);
            chatInterface.style.height = "300px";
            break;
        case 670:
        case 669:
        case 668:
        case 663:
        case 662:
        case 661:
        case 696: // iPhone 6 Plus, 6s Plus, 7 Plus, 8 Plus
            inputContainerRef.style.top = getInputContainerSetTopValue(315);
            chatInterface.style.height = "278px";
            break;
        case 664: // iPhone 13, iPhone 13 pro
            inputContainerRef.style.top = getInputContainerSetTopValue(210);
            chatInterface.style.height = "180px";
            break;
        case 635: 
        case 629: 
        case 627: 
        case 617: 
            inputContainerRef.style.top = getInputContainerSetTopValue(210);
            chatInterface.style.height = "180px";
            break;
        case 548:
        case 528: // iPhone 5s, SE, iOS 12.4
            inputContainerRef.style.top = getInputContainerSetTopValue(156);
            chatInterface.style.height = "180px";
            break;
        default:
            inputContainerRef.style.top = getInputContainerSetTopValue(topOffeset);
            chatInterface.style.height = `${interfaceHeight}px`;
    }

    setTimeout(() => {
        chatInterface.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 150);

    if (ticketsHeader) {
        ticketsHeader.addEventListener("touchmove", (e) => {
            e.preventDefault();
        });
    }

    if (chatInterface) {
        chatInterface.addEventListener("touchmove", (e) => {
            if (!e.currentTarget) {
                return;
            }
            if (e.currentTarget.scrollTop === 0) {
                e.currentTarget.scrollTop = 1;
            } else if (
                e.currentTarget.scrollHeight ===
                e.currentTarget.scrollTop + e.currentTarget.offsetHeight
            ) {
                e.currentTarget.scrollTop -= 1;
            }
        });
    }

    return "";
};

export const getNewStylePixel = (currentPixel, reducedBy) => {
    const currentPixelInNumber = Number.parseFloat(
        currentPixel?.replace("px", "")
    );
    const newPixel = currentPixelInNumber - reducedBy;
    return `${newPixel}px`;
};

export const removeMobileKeyboard = () => {
    const chatInterface = document.getElementById("messageBody");
    const ticketsHeader = document.getElementById("header__wrapper");
    const inputContainerRef = document.getElementById("inputGroup");
    const textAreaField = document.getElementById("form__input");

    document.body.classList.remove?.("keyboard");

    inputContainerRef.style.position = "fixed";
    inputContainerRef.style.bottom = "0px !important";
    textAreaField?.blur?.();
    // inputContainerRef.style.left = "99999px";
    inputContainerRef.style.top = "initial";
    inputContainerRef?.removeEventListener?.("touchmove", (e) => {
        e.preventDefault();
    });

    chatInterface.style.height = "80vh";
    if (ticketsHeader) {
        ticketsHeader?.removeEventListener?.("touchmove", (e) => {
            e.preventDefault();
        });
    }
};

export const expandKeyboardTextArea = (textFieldHeightRef) => {
    const inputContainerRef = document.getElementById("inputGroup");
    const textAreaField = document.getElementById("form__input");
    const textAreaFieldHeight = textAreaField?.scrollHeight;
    const textAreaFieldContainer =
        document.getElementById("chat__input--group");

    let isChanging = false;
    if (textAreaFieldHeight !== textFieldHeightRef?.current) {
        isChanging = true;
    } else {
        isChanging = false;
    }

    if (textAreaFieldHeight <= 58) {
        textAreaFieldContainer.style.height = "58px";
        textAreaFieldContainer.style.borderRadius = "100px";

        inputContainerRef.style.top = getNewStylePixel(
            inputContainerRef.style.top,
            textAreaFieldHeight - textFieldHeightRef.current
        );

        textFieldHeightRef.current = textAreaFieldHeight;
    } else if (textAreaFieldHeight > 58 && textAreaFieldHeight <= 106) {
        textAreaFieldContainer.style.height = `${textAreaFieldHeight}px`;
        textAreaFieldContainer.style.borderRadius = "10px";

        if (isChanging) {
            inputContainerRef.style.top = getNewStylePixel(
                inputContainerRef.style.top,
                textAreaFieldHeight - textFieldHeightRef.current
            );
        }

        textFieldHeightRef.current = textAreaFieldHeight;
    } else if (textAreaFieldHeight > 106) {
        textAreaFieldContainer.style.height = `138px`;
        textAreaFieldContainer.style.borderRadius = "10px";

        if (textAreaFieldHeight <= 130) {
            if (isChanging && textAreaFieldHeight <= 130) {
                inputContainerRef.style.top = getNewStylePixel(
                    inputContainerRef.style.top,
                    textAreaFieldHeight - textFieldHeightRef.current
                );
            }

            textFieldHeightRef.current = textAreaFieldHeight;
        }
    }
    textAreaField?.addEventListener?.("input", function () {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
        if (textAreaFieldHeight <= 106) {
            this.style.height = this.scrollHeight + "px";
        }
    });
};
